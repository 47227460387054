import * as R from 'ramda';

export const parse = (str) => {
  if(!str) {
    return {};
  }
  const result = R.pipe(
    R.replace('?', ''),
    R.split('&'),
    R.map((item) => R.split('=', decodeURI(item))),
    R.fromPairs,
  )(str);
  return result
}

export const stringify = (query) => {
  if(R.isEmpty(query)){
    return ''
  }
  const keys = Object.keys(query);
  let result = ''
  keys.map((key) => {
    result += `&${key}=${query[key]}`
  });
  const params = new URLSearchParams(result)
  return params.toString() || '';
}


